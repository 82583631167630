// Copyright 2023 Merit International Inc. All Rights Reserved

import { ConfirmationModal } from "../../../components/Modals";
import { ConnectedOrgFilter } from "./ConnectedOrgFilter";
import { ConnectedOrganizationsDetails } from "../../ConnectedOrganizationDetails/ConnectedOrganizationDetails";
import { ConnectedOrganizationsTable } from "./ConnectedOrganizationsTable";
import { Drawer } from "@src/components/Drawer";
import { Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { ListTemplatesTypeEnum } from "../../../gen/org-portal";
import { Pagination, Spin } from "../../../components";
import { RecordDetails } from "@src/screens/Records";
import { ScrollView, View } from "react-native";
import { useAcceptedFolioStore, useAlertStore } from "../../../stores";
import { useApi } from "../../../api/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { useFoliosData } from "./useFoliosData";
import { useLoadedConfigurationState } from "../../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type {
  OrgsGet200ResponseContainersInner as Container,
  GetContainersRequest,
} from "../../../gen/org-portal";
import type { FilterValues } from "./ConnectedOrgFilter";
import type { ReactNode } from "react";

export const SCREEN_NAME = "connectedOrganizations";

const { None, Some } = Helpers;

type Props = {
  readonly searchQuery?: string | undefined;
};

const THE_END_OF_TIME = "6666-12-01T00:00:00.000000Z";

export const ConnectedOrganizations = ({ searchQuery }: Props) => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const { setUserAcceptedAccountFolio } = useAcceptedFolioStore();
  const appConfig = useLoadedConfigurationState();
  const { errorHandler } = useServerErrorHandler();
  const { theme } = useTheme();
  const isFocus = useIsFocused();

  const [foliosSearchResults, setFoliosSearchResults] = useState<readonly Container[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolio, setSelectedFolio] = useState<Container>();
  const [baseTemplateFields, setBaseTemplateFields] = useState<{
    readonly issuingOrgNameField: string;
    readonly organizationNameField: string;
  }>();
  const [confirmationModal, setConfirmationModal] = useState<ReactNode>();
  const [selectedFilterValue, setSelectedFilterValue] = useState<FilterValues>();
  const [navigateToPrevPage, setNavigateToPrevPage] = useState(false);

  const {
    data,
    isLoading: isFetching,
    nextPage,
    pagination,
    prevPage,
    refresh,
    setPage,
  } = useFoliosData(
    api,
    {
      end: THE_END_OF_TIME,
      limit: 10,
      orgID: selectedOrgId,
      sortBy: "createdAt",
      templateType: ListTemplatesTypeEnum.Folio,
    },
    isFocus
  );

  const containersData = useMemo(() => {
    if (navigateToPrevPage) {
      return data?.toReversed();
    }

    return data;
  }, [data, navigateToPrevPage]);

  const getFoliosTemplates = useCallback(async () => {
    const response = await api.listTemplates({ orgID: selectedOrgId, type: "Folio" });
    if (response.templates.length > 0) {
      const folioFields = response.templates[0].templateFields?.reduce(
        (prev, current) => {
          if (current.name === "Issuing Org Name" && Some(current.lineage)) {
            return {
              ...prev,
              issuingOrgNameField: current.lineage[0],
            };
          }

          if (current.name === "Organization Name" && Some(current.lineage)) {
            return {
              ...prev,
              organizationNameField: current.lineage[0],
            };
          }

          return prev;
        },
        { issuingOrgNameField: "", organizationNameField: "" }
      );
      setBaseTemplateFields(folioFields);
    }
  }, [api, selectedOrgId]);

  useEffect(() => {
    getFoliosTemplates();
  }, [getFoliosTemplates]);

  const fetchFoliosBySearchQuery = useCallback(async () => {
    try {
      setIsLoading(true);
      const { containers, hasMore } = await api.search({
        orgID: selectedOrgId,
        query: {
          containerFields: [
            {
              baseTemplateFieldID: baseTemplateFields?.organizationNameField,
              query: searchQuery,
            },
          ],
        },
      });
      if (Some(hasMore) && hasMore) {
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: "Your search returned more than 500 results. Please refine you search",
          type: "warning",
        });
      }
      setFoliosSearchResults(containers ?? []);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, baseTemplateFields, deleteAlert, errorHandler, searchQuery, selectedOrgId, setAlert]);

  useFocusEffect(
    useCallback(() => {
      if (Some(searchQuery)) {
        fetchFoliosBySearchQuery();
      } else {
        setFoliosSearchResults(undefined);
      }
    }, [fetchFoliosBySearchQuery, searchQuery])
  );

  const updateList = useCallback(() => {
    if (Some(searchQuery)) {
      fetchFoliosBySearchQuery();
    } else {
      refresh();
    }
  }, [fetchFoliosBySearchQuery, refresh, searchQuery]);

  const successAlert = useCallback(
    (message: string) => {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        text: message,
        type: "success",
      });
    },
    [deleteAlert, setAlert]
  );

  const acceptFolio = useCallback(
    async (folioId: string, folioTemplateId: string | undefined) => {
      if (Some(selectedOrgId)) {
        try {
          setIsLoading(true);
          await api.acceptContainer({
            containerID: folioId,
            orgID: selectedOrgId,
          });
          successAlert("A folio has been accepted.");
          if (
            folioTemplateId !== undefined &&
            folioTemplateId === appConfig.configuration.accountFolioTemplateUUID
          ) {
            setUserAcceptedAccountFolio(true);
          }
          setSelectedFolio(undefined);
          updateList();
        } catch (error) {
          const testProps = {
            elementName: "acceptFolioError",
            screenName: SCREEN_NAME,
          };
          errorHandler(error, testProps);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      api,
      appConfig.configuration.accountFolioTemplateUUID,
      errorHandler,
      selectedOrgId,
      setUserAcceptedAccountFolio,
      successAlert,
      updateList,
    ]
  );

  const rejectFolio = useCallback(
    async (folioId: string | undefined) => {
      if (Some(folioId)) {
        try {
          setIsLoading(true);
          await api.rejectContainer({
            containerID: folioId.toString(),
            orgID: selectedOrgId,
          });
          successAlert("A folio has been rejected.");
          setSelectedFolio(undefined);
          updateList();
        } catch (error) {
          const testProps = {
            elementName: "rejectFolioError",
            screenName: SCREEN_NAME,
          };
          errorHandler(error, testProps);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [api, errorHandler, selectedOrgId, successAlert, updateList]
  );

  const handleRemoveContainer = useCallback(
    async (containerId: string) => {
      setIsLoading(true);
      try {
        await api.deleteContainer({
          containerID: containerId,
          orgID: selectedOrgId,
        });
        successAlert("A folio has been removed.");
        setSelectedFolio(undefined);
        updateList();
      } catch (error: unknown) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [api, errorHandler, selectedOrgId, successAlert, updateList]
  );

  const reissueContainer = useCallback(
    async (containerID: string) => {
      setIsLoading(true);
      try {
        await api.authorizeContainer({
          containerID,
          orgID: selectedOrgId,
        });
        successAlert("A folio has been reissued.");
        setSelectedFolio(undefined);
        updateList();
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [api, errorHandler, selectedOrgId, successAlert, updateList]
  );

  const revokeContainer = useCallback(
    async (containerID: string) => {
      setIsLoading(true);
      try {
        await api.revokeContainer({
          containerID,
          orgID: selectedOrgId,
        });
        successAlert("A folio has been revoked.");
        setSelectedFolio(undefined);
        updateList();
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [api, errorHandler, selectedOrgId, successAlert, updateList]
  );

  const fetchRecordBasedOnInterAction = useCallback(
    (values: FilterValues) => {
      const defaultParams: GetContainersRequest = {
        end: THE_END_OF_TIME,
        orgID: selectedOrgId,
        start: undefined,
      };

      refresh({
        ...defaultParams,
        issuerID: values.interaction === "issued" ? selectedOrgId : undefined,
        recipientID: values.interaction === "received" ? selectedOrgId : undefined,
        state: values.status,
      });

      setSelectedFilterValue(values);
      setPage(0);
    },
    [refresh, selectedOrgId, setPage]
  );

  const createRejectFolioConfirmationModal = useCallback(
    (containerId: string) => (
      <ConfirmationModal
        buttonText="reject"
        onClose={() => {
          setConfirmationModal(undefined);
        }}
        onOk={() => {
          rejectFolio(containerId);
          setConfirmationModal(undefined);
        }}
        testProps={{
          elementName: "folioRejectConfirmationModal",
          screenName: SCREEN_NAME,
        }}
        text="Are you sure you want to reject this folio as an admin? You cannot view this folio in your list anymore if you remove."
        title="Reject this folio"
      />
    ),
    [rejectFolio]
  );

  const createReissueConfirmationModal = useCallback(
    (containerId: string) => (
      <ConfirmationModal
        buttonText="reissue"
        onClose={() => {
          setConfirmationModal(undefined);
        }}
        onOk={() => {
          reissueContainer(containerId);
          setConfirmationModal(undefined);
        }}
        testProps={{
          elementName: "folioReissueConfirmationModal",
          screenName: SCREEN_NAME,
        }}
        text="Are you sure you want to reissue this folio?"
        title="Reissue this folio"
      />
    ),
    [reissueContainer]
  );

  const createRevokeConfirmationModal = useCallback(
    (containerId: string) => (
      <ConfirmationModal
        buttonText="revoke"
        onClose={() => {
          setConfirmationModal(undefined);
        }}
        onOk={() => {
          revokeContainer(containerId);
          setConfirmationModal(undefined);
        }}
        testProps={{
          elementName: "folioRevokeConfirmationModal",
          screenName: SCREEN_NAME,
        }}
        text="Are you sure you want to revoke this folio as an admin? In order to reissue it you’ll have to reissue it from the revoked status"
        title="Revoke this folio"
      />
    ),
    [revokeContainer]
  );

  const createRemoveConfirmationModal = useCallback(
    (containerId: string) => (
      <ConfirmationModal
        buttonText="remove"
        onClose={() => {
          setConfirmationModal(undefined);
        }}
        onOk={() => {
          handleRemoveContainer(containerId);
          setConfirmationModal(undefined);
        }}
        testProps={{
          elementName: "folioRemoveConfirmationModal",
          screenName: SCREEN_NAME,
        }}
        text="Are you sure you want to remove this folio as an admin? You cannot view this folio in your list anymore if you remove."
        title="Remove this folio"
        titleIconName="warningMediumCritical"
      />
    ),
    [handleRemoveContainer]
  );

  return (
    <>
      {None(searchQuery) && (
        <ConnectedOrgFilter
          onSelect={fetchRecordBasedOnInterAction}
          placeholder="All"
          selectedFilterValues={selectedFilterValue}
        />
      )}
      <View style={{ height: theme.spacing.xxl }} />
      <View style={{ flex: 1 }}>
        <Spin spinning={isLoading || isFetching}>
          <ScrollView>
            {Some(foliosSearchResults) && (
              <View style={{ paddingLeft: 32 }}>
                <Heading level="4">{`Results(${foliosSearchResults.length})`}</Heading>
              </View>
            )}
            <ConnectedOrganizationsTable
              containers={Some(searchQuery) ? foliosSearchResults : containersData}
              onAccept={acceptFolio}
              onReject={containerId => {
                setConfirmationModal(createRejectFolioConfirmationModal(containerId));
              }}
              onRemove={containerId => {
                setConfirmationModal(createRemoveConfirmationModal(containerId));
              }}
              onView={setSelectedFolio}
            />
          </ScrollView>

          {None(searchQuery) && (
            <Pagination
              disableNext={!pagination.hasNextPage || isLoading}
              disablePrev={!pagination.hasPrevPage || isLoading}
              onNext={() => {
                setNavigateToPrevPage(false);
                nextPage();
              }}
              onPrev={() => {
                setNavigateToPrevPage(true);
                prevPage();
              }}
              testProps={{
                elementName: "ConnectedOrgListView",
                screenName: SCREEN_NAME,
              }}
            />
          )}
          {confirmationModal}
        </Spin>

        <ConnectedOrganizationsDetails
          acceptFolio={acceptFolio}
          container={selectedFolio}
          isOpen={Some(selectedFolio) && selectedFolio.recipient?.id === selectedOrgId}
          onDrawerClose={() => {
            setSelectedFolio(undefined);
          }}
          onReject={containerId => {
            setConfirmationModal(createRejectFolioConfirmationModal(containerId ?? ""));
          }}
        />

        <Drawer isOpen={Some(selectedFolio) && selectedFolio.issuer?.id === selectedOrgId}>
          {Some(selectedFolio) && selectedFolio.issuer?.id === selectedOrgId && (
            <RecordDetails
              id={selectedFolio.id}
              onDrawerClose={() => {
                setSelectedFolio(undefined);
              }}
              onPressDelete={() => {
                setConfirmationModal(createRemoveConfirmationModal(selectedFolio.id));
              }}
              onPressReissue={() => {
                setConfirmationModal(createReissueConfirmationModal(selectedFolio.id));
              }}
              onPressRevoke={() => {
                setConfirmationModal(createRevokeConfirmationModal(selectedFolio.id));
              }}
              templateID={selectedFolio.templateId}
            />
          )}
        </Drawer>
      </View>
    </>
  );
};
