/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateGroupProperties,
  Errors,
  Group,
  GroupsResponse,
} from '../models/index';
import {
    CreateGroupPropertiesFromJSON,
    CreateGroupPropertiesToJSON,
    ErrorsFromJSON,
    ErrorsToJSON,
    GroupFromJSON,
    GroupToJSON,
    GroupsResponseFromJSON,
    GroupsResponseToJSON,
} from '../models/index';

export interface CreateGroupRequest {
    authorization?: string;
    properties?: CreateGroupProperties;
}

export interface GetGroupRequest {
    groupID: string;
    authorization?: string;
}

export interface GetGroupsRequest {
    authorization?: string;
    startingAfter?: string;
    endingBefore?: string;
    limit?: number;
    sortBy?: GetGroupsSortByEnum;
}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI {

    /**
     * `create:group`  Create a group belonging to the org that owns the entity 
     * Create Group
     */
    async createGroupRaw(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Group>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGroupPropertiesToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupFromJSON(jsonValue));
    }

    /**
     * `create:group`  Create a group belonging to the org that owns the entity 
     * Create Group
     */
    async createGroup(requestParameters: CreateGroupRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Group> {
        const response = await this.createGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:group`  Return a single group specified by groupID 
     * Get Group
     */
    async getGroupRaw(requestParameters: GetGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Group>> {
        if (requestParameters.groupID === null || requestParameters.groupID === undefined) {
            throw new runtime.RequiredError('groupID','Required parameter requestParameters.groupID was null or undefined when calling getGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/groups/{groupID}`.replace(`{${"groupID"}}`, encodeURIComponent(String(requestParameters.groupID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupFromJSON(jsonValue));
    }

    /**
     * `read:group`  Return a single group specified by groupID 
     * Get Group
     */
    async getGroup(requestParameters: GetGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Group> {
        const response = await this.getGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:group`  Returns a paginated list of groups belonging to the requesting organization 
     * Get Groups
     */
    async getGroupsRaw(requestParameters: GetGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.startingAfter !== undefined) {
            queryParameters['starting_after'] = requestParameters.startingAfter;
        }

        if (requestParameters.endingBefore !== undefined) {
            queryParameters['ending_before'] = requestParameters.endingBefore;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupsResponseFromJSON(jsonValue));
    }

    /**
     * `read:group`  Returns a paginated list of groups belonging to the requesting organization 
     * Get Groups
     */
    async getGroups(requestParameters: GetGroupsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupsResponse> {
        const response = await this.getGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetGroupsSortByEnum = {
    Name: 'name',
    CreatedAt: 'createdAt'
} as const;
export type GetGroupsSortByEnum = typeof GetGroupsSortByEnum[keyof typeof GetGroupsSortByEnum];
