// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, Icon, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "@src/components/Modals";
import { DetailsDrawer } from "../../../layouts/DetailsDrawer";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../../components/Spacer";
import { Overview } from "./Overview";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Spin, Tabs } from "../../../components";
import { Versions } from "./Versions";
import { useAlertStore } from "@src/stores";
import { useApi } from "../../../api/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import type {
  GetAgents200ResponseAgentsInner,
  GetAllAgentsVersions200ResponseInner,
} from "../../../gen/org-portal";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";

type TabKey = "overview" | "versions";
type Tab = { readonly key: TabKey; readonly label: string };

export const SCREEN_NAME = "LinkedAppDetails";
export type AppsListType = "linked" | "own";

const { None, Some } = Helpers;

type Props = {
  readonly appID: string | undefined;
  readonly onClose: () => void;
  readonly onStatusChange: () => void;
  readonly appsListType: AppsListType;
};

export const AppDetails = ({ appID, appsListType, onClose, onStatusChange }: Props) => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { errorHandler } = useServerErrorHandler();
  const { deleteAlert, setAlert } = useAlertStore();
  const { allowOrgPortalAgentsEdit } = useFlags<LDFeatureFlags>();

  const [activeTab, setActiveTab] = useState<TabKey>("overview");
  const [appDetails, setAppDetails] = useState<
    GetAgents200ResponseAgentsInner | GetAllAgentsVersions200ResponseInner
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const styles = StyleSheet.create({
    center: {
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    divider: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingHorizontal: 32,
    },
  });

  useEffect(() => {
    const fetchAppDetails = async () => {
      if (None(appID)) {
        return;
      }

      setIsLoading(true);
      try {
        if (appsListType === "own") {
          const response = await api.getAgentVersions({ agentID: appID, orgID: selectedOrgId });
          setAppDetails({
            ...response,
            versions: response.versions.toSorted((a, b) => (a.version > b.version ? -1 : 1)),
          });
        } else {
          const response = await api.getAgent({ agentID: appID, orgID: selectedOrgId });
          setAppDetails(response);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAppDetails();
  }, [api, appID, errorHandler, selectedOrgId, appsListType]);

  const changeAppState = useCallback(async () => {
    if (None(appDetails)) {
      throw new Error("Somehow appDetails are not found");
    }

    try {
      setIsLoading(true);
      const appState = appDetails.status === "live" ? "paused" : "live";
      await api.updateAgent({
        agentID: appDetails.id,
        orgID: selectedOrgId,
        properties: {
          status: appState,
        },
      });
      setAppDetails(prevAppDetails => {
        if (Some(prevAppDetails)) {
          return {
            ...prevAppDetails,
            status: appState,
          };
        }

        return undefined;
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps: {
          elementName: "updateAppSuccess",
          screenName: SCREEN_NAME,
        },
        text: "The app status has been updated.",
        type: "success",
      });
      onStatusChange();
    } catch (error) {
      errorHandler(error, {
        elementName: "updateAppError",
        screenName: SCREEN_NAME,
      });
    } finally {
      setShowConfirmationModal(false);
      setIsLoading(false);
    }
  }, [api, appDetails, deleteAlert, errorHandler, onStatusChange, selectedOrgId, setAlert]);

  const tabs: readonly Tab[] = useMemo(() => {
    const overView = {
      key: "overview",
      label: "Overview",
    } as Tab;

    if (appsListType === "linked") {
      return [overView];
    }

    return [
      overView,
      {
        key: "versions",
        label: "Versions",
      },
    ];
  }, [appsListType]);

  if (isLoading) {
    return (
      <View style={[styles.container, styles.center]}>
        <Spin />
      </View>
    );
  }

  if (None(appDetails)) {
    return (
      <View style={styles.container}>
        <View
          style={{ flexDirection: "row", justifyContent: "flex-end", padding: theme.spacing.l }}
        >
          <Pressable onPress={onClose} style={{ padding: theme.spacing.l }}>
            <Icon name="closeSmallDefault" />
          </Pressable>
        </View>
        <View style={[styles.center, { flex: 1 }]}>
          <Body>Something went wrong. Please try again</Body>
        </View>
      </View>
    );
  }

  const appName = "versions" in appDetails ? appDetails.versions[0].name : appDetails.name;
  const owner = "versions" in appDetails ? appDetails.owner : appDetails.org;
  const versions = "versions" in appDetails ? appDetails.versions : [];

  return (
    <>
      <DetailsDrawer
        actionButtons={
          allowOrgPortalAgentsEdit &&
          selectedOrgId === owner && (
            <>
              <View style={{ width: 87 }}>
                <Button
                  onPress={() => {
                    setShowConfirmationModal(true);
                  }}
                  size="small"
                  testProps={{
                    elementName: "appDetailsViewEditButton",
                    screenName: SCREEN_NAME,
                  }}
                  text={appDetails.status === "live" ? "Pause" : "Publish"}
                  type="secondary"
                />
              </View>
              <HorizontalSpacer />
            </>
          )
        }
        onPressClose={() => {
          setActiveTab(tabs[0].key);
          onClose();
          setAppDetails(undefined);
        }}
        subTitle={
          <View style={{ flexDirection: "row" }}>
            <Heading
              level="6"
              testProps={{
                elementId: appDetails.id,
                elementName: "appDetailsViewAppID",
                screenName: SCREEN_NAME,
              }}
            >
              App ID: {appDetails.id}
            </Heading>
            <HorizontalSpacer size={24} />

            <Heading color={theme.colors.border.subdued} level="6">
              |
            </Heading>
            <HorizontalSpacer size={24} />
            <Heading
              capitalize
              level="6"
              testProps={{
                elementId: appDetails.id,
                elementName: "appDetailsViewStatus",
                screenName: SCREEN_NAME,
              }}
            >
              Status: {appDetails.status}
            </Heading>
            <HorizontalSpacer size={24} />

            <Heading color={theme.colors.border.subdued} level="6">
              |
            </Heading>
            <HorizontalSpacer size={24} />
            <Heading
              capitalize
              level="6"
              testProps={{
                elementId: appDetails.id,
                elementName: "appDetailsViewState",
                screenName: SCREEN_NAME,
              }}
            >
              State: {appDetails.state === "live" ? "Live" : "Pending"}
            </Heading>
          </View>
        }
        testProps={{
          elementId: appDetails.id,
          elementName: "appDetailsView",
          screenName: SCREEN_NAME,
        }}
        title={appName}
      >
        <View style={styles.divider}>
          <Tabs
            items={tabs}
            onChange={event => {
              setActiveTab(event);
            }}
            selected={activeTab}
            testProps={{
              elementId: appDetails.id,
              elementName: "appDetailsView",
              screenName: SCREEN_NAME,
            }}
          />
        </View>
        <ScrollView>
          {activeTab === "overview" && <Overview appDetails={appDetails} />}
          {appsListType === "own" && activeTab === "versions" && <Versions versions={versions} />}
        </ScrollView>
      </DetailsDrawer>
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onOk={() => {
            changeAppState();
          }}
          testProps={{
            elementName: `app${appDetails.status === "live" ? "Pause" : "Publish"}Confirmation`,
            screenName: SCREEN_NAME,
          }}
          text={
            appDetails.status === "live"
              ? "By pausing this app, some linked apps may not function properly."
              : "By publishing this app, you are allowing linked apps to access and use this app."
          }
          title={`Are you sure you want to ${
            appDetails.status === "live" ? "pause" : "publish"
          } this app?`}
        />
      )}
    </>
  );
};
