// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button } from "@merit/frontend-components";
import {
  type OrgsGet200ResponseContainersInner as Container,
  GetContainersStateEnum as ContainerState,
} from "../../../gen/org-portal";
import { Datagrid, DatagridBody, EllipsisText, HorizontalSpacer } from "../../../components";
import { Helpers } from "@merit/frontend-utils";
import { View } from "react-native";
import { getDateTimeString } from "../../../utils/time";
import { useAuthStore } from "@src/stores";
import { useGetContainerValueByBaseFieldName } from "../../../utils/getContainerFieldValue";
import type { DatagridColumn } from "../../../components/Datagrid/types";

const { Some } = Helpers;
const SCREEN_NAME = "ConnectedOrganizations";

type Props = {
  readonly containers?: readonly Container[];
  readonly onView: (container: Container) => void;
  readonly onAccept: (folioId: string, folioTemplateID?: string) => Promise<void>;
  readonly onReject: (folioId: string) => void;
  readonly onRemove: (folioId: string) => void;
};

export const containerStatesToShowRejectButton: readonly string[] = [
  ContainerState.Accepted,
  ContainerState.Pending,
];

export const ConnectedOrganizationsTable = ({
  containers,
  onAccept,
  onReject,
  onRemove,
  onView,
}: Props) => {
  const { getContainerValueByBaseFieldName } = useGetContainerValueByBaseFieldName();
  const { selectedOrgId } = useAuthStore();

  const sortedContainers = [
    ...(containers?.filter(
      container => container.state?.name === "pending" && container.recipient?.id === selectedOrgId
    ) ?? []),
    ...(containers?.filter(
      container => container.state?.name === "pending" && container.recipient?.id !== selectedOrgId
    ) ?? []),
    ...(containers?.filter(_ => _.state?.name !== "pending") ?? []),
  ];

  const columns: readonly DatagridColumn<Container>[] = [
    {
      key: "issueOrgName",
      label: "Organization",
      renderer: (container, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={getContainerValueByBaseFieldName({
            container,
            fieldName: "issuingOrgName",
            templateType: "folio",
          })}
        />
      ),
      size: "flex",
    },
    {
      key: "name",
      label: "Template",
      size: "flex",
    },
    {
      key: "interaction",
      label: "Interaction",
      renderer: (container, testProps) => (
        <Body testProps={testProps}>
          {container.recipient?.id === selectedOrgId ? "Received" : "Issued"}
        </Body>
      ),
      size: 170,
    },
    {
      key: "authorizedAt",
      label: "Date",
      renderer: ({ authorizedAt }, testProps) =>
        Some(authorizedAt) ? (
          <Body testProps={testProps}>{getDateTimeString(authorizedAt.toString())}</Body>
        ) : (
          "--"
        ),
      size: 200,
    },
    {
      key: "active",
      label: "Status",
      renderer: ({ state }, testProps) => (
        <Body capitalize testProps={testProps}>
          {state?.name ?? "--"}
        </Body>
      ),
      size: 100,
    },
    {
      key: "actions",
      label: "Actions",
      renderer: (container, testProps) => {
        const isReceivedFolio = container.recipient?.id === selectedOrgId;

        return (
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: 80 }}>
              <Button
                onPress={() => {
                  onView(container);
                }}
                size="small"
                testProps={
                  Some(testProps)
                    ? {
                        ...testProps,
                        elementName: `${testProps.elementName}ViewButton`,
                      }
                    : testProps
                }
                text="View"
                type="secondary"
              />
            </View>

            {isReceivedFolio ? (
              Some(container.state) &&
              containerStatesToShowRejectButton.includes(container.state.name) && (
                <>
                  <HorizontalSpacer />
                  <View style={{ width: 32 }}>
                    <Button
                      iconRight="closeSmallDefault"
                      onPress={() => {
                        onReject(container.id);
                      }}
                      size="small"
                      testProps={
                        Some(testProps)
                          ? {
                              ...testProps,
                              elementName: `${testProps.elementName}RejectButton`,
                            }
                          : testProps
                      }
                      type="destructive"
                    />
                  </View>
                </>
              )
            ) : (
              <>
                <HorizontalSpacer />
                <View style={{ width: 32 }}>
                  <Button
                    iconRight="closeSmallDefault"
                    onPress={() => {
                      onRemove(container.id);
                    }}
                    size="small"
                    testProps={
                      Some(testProps)
                        ? {
                            ...testProps,
                            elementName: `${testProps.elementName}RemoveButton`,
                          }
                        : testProps
                    }
                    type="destructive"
                  />
                </View>
              </>
            )}
            {isReceivedFolio && container.state?.name === "pending" && (
              <>
                <HorizontalSpacer />
                <View style={{ width: 110 }}>
                  <Button
                    iconLeft="checkmarkSuccess"
                    onPress={() => onAccept(container.id, container.templateId)}
                    size="small"
                    testProps={
                      Some(testProps)
                        ? {
                            ...testProps,
                            elementName: `${testProps.elementName}AcceptButton`,
                          }
                        : testProps
                    }
                    text="Accept"
                  />
                </View>
              </>
            )}
          </View>
        );
      },
      size: "flex",
    },
  ];

  return (
    <Datagrid
      columns={columns}
      testProps={{
        elementName: "connectedOrganizationsListView",
        screenName: SCREEN_NAME,
      }}
    >
      <DatagridBody
        columns={columns}
        data={sortedContainers}
        testProps={{
          elementName: "connectedOrganizationsListView",
          screenName: SCREEN_NAME,
        }}
        testPropsElementIdKey="id"
      />
    </Datagrid>
  );
};
