/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Formula } from './Formula';
import {
    FormulaFromJSON,
    FormulaFromJSONTyped,
    FormulaToJSON,
} from './Formula';

/**
 * The required inputs to create a Group.
 * @export
 * @interface CreateGroupProperties
 */
export interface CreateGroupProperties {
    /**
     * the name of the group
     * @type {string}
     * @memberof CreateGroupProperties
     */
    name: string;
    /**
     * The group description, e.g. a summary of the rule that defines the group
     * @type {string}
     * @memberof CreateGroupProperties
     */
    description: string;
    /**
     * 
     * @type {Formula}
     * @memberof CreateGroupProperties
     */
    rule?: Formula;
}

/**
 * Check if a given object implements the CreateGroupProperties interface.
 */
export function instanceOfCreateGroupProperties(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function CreateGroupPropertiesFromJSON(json: any): CreateGroupProperties {
    return CreateGroupPropertiesFromJSONTyped(json, false);
}

export function CreateGroupPropertiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateGroupProperties {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'rule': !exists(json, 'rule') ? undefined : FormulaFromJSON(json['rule']),
    };
}

export function CreateGroupPropertiesToJSON(value?: CreateGroupProperties | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'rule': FormulaToJSON(value.rule),
    };
}

