// Copyright 2023 Merit International Inc. All Rights Reserved

import { AppDetails } from "../AppDetails/AppDetails";
import { AppsTable } from "../AppTable/AppsTable";
import { Drawer } from "../../../components/Drawer";
import { GetAllAgentsVersionsSortByEnum } from "../../../gen/org-portal";
import { Helpers } from "@merit/frontend-utils";
import { Pagination, Spin } from "../../../components";
import { View } from "react-native";
import { useApi } from "../../../api/api";
import { useLinkedAppsData } from "./useLinkedAppsData";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useState } from "react";

const { Some } = Helpers;

// Fetching an additional record to manage pagination since the response does not include a cursor.
const AGENTS_LIMIT = 11;

export const LinkedApps = () => {
  const { api } = useApi();

  const { selectedOrgId } = useLoggedInAuthState();
  const [selectedAppID, setSelectedAppID] = useState<string>();

  const { agents, isLoading, nextPage, paginationData, prevPage, refresh } = useLinkedAppsData(
    api,
    {
      entityID: selectedOrgId,
      limit: AGENTS_LIMIT,
      orgID: selectedOrgId,
      sortBy: GetAllAgentsVersionsSortByEnum.Name,
    }
  );

  return (
    <>
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Spin />
        </View>
      ) : (
        <>
          <AppsTable
            appsList={agents}
            onPressView={id => {
              setSelectedAppID(id);
            }}
          />
          <Drawer isOpen={Some(selectedAppID)}>
            <AppDetails
              appID={selectedAppID}
              appsListType="linked"
              onClose={() => {
                setSelectedAppID(undefined);
              }}
              onStatusChange={refresh}
            />
          </Drawer>
        </>
      )}

      <Pagination
        disableNext={!paginationData.hasNextPage || isLoading}
        disablePrev={!paginationData.hasPrevPage || isLoading}
        onNext={nextPage}
        onPrev={prevPage}
      />
    </>
  );
};
