// Copyright 2023 Merit International Inc. All Rights Reserved

import { ConfirmationModal } from "../../../src/components/Modals";
import { Drawer } from "../../components/Drawer";
import { Helpers } from "@merit/frontend-utils";
import { NoRecords } from "./NoRecords";
import { RecordDetails } from "../RecordDetails/RecordDetails";
import { RecordsScreen } from "..";
import { ScrollView, View } from "react-native";
import { SearchForm } from "./SearchForm";
import { SearchResults } from "./SearchResults";
import { Spin } from "../../components";
import { convertDateToTimeZone, endOfDate } from "@src/utils/time";
import { getContainerFieldValue } from "@src/utils/getContainerFieldValue";
import { useAlertStore, useAppConstantsStore, useBaseFieldIdStore } from "../../stores";
import { useApi } from "../../api/api";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useInferContainerTemplateType } from "@src/utils/inferContainerTemplateType";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/native";
import { useRecordsSearchStyles } from "./styles";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useEffect, useState } from "react";
import type { LDFeatureFlags } from "../../configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { OrgsGet200ResponseContainersInner } from "@src/gen/org-portal";
import type { ReactNode } from "react";
import type { RouteParams } from "../../Router";
import type { SearchFormValues, SearchResultList } from "./types";

const THE_END_OF_TIME = "6666-12-01T00:00:00.000000Z";

export const SCREEN_NAME = "RecordsSearch";

const { None, Some } = Helpers;

export const Records = () => {
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { deleteAlert, setAlert } = useAlertStore();
  const { errorHandler } = useServerErrorHandler();
  const [confirmationModal, setConfirmationModal] = useState<ReactNode>();
  const [recordToView, setRecordToView] = useState<OrgsGet200ResponseContainersInner | undefined>();
  const styles = useRecordsSearchStyles();
  const [searchResults, setSearchResults] = useState<SearchResultList>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { fieldIds } = useBaseFieldIdStore();
  const [lastSearchQuery, setLastSearchQuery] = useState<SearchFormValues>();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "Records">>();
  const { DefaultLayout } = useFlaggedLayout();
  const [recentContainers, setRecentContainers] = useState<SearchResultList>();
  const [loadingRecentContainers, setLoadingRecentContainers] = useState<boolean>(false);
  const [orgTimeZone, setOrgTimeZone] = useState<string>();
  const { accountFolioFieldNames } = useAppConstantsStore();
  const { configuration } = useLoadedConfigurationState();

  const { inferContainerTemplateType } = useInferContainerTemplateType();

  const resetSearch = () => {
    setRecordToView(undefined);
    setSearchResults(undefined);
    setRecentContainers(undefined);
  };

  useEffect(() => navigation.addListener("blur", resetSearch), [navigation]);

  useEffect(() => {
    const getOrgInfo = async () => {
      try {
        const response = await api.getOrgDetails({ orgID: selectedOrgId });
        setOrgTimeZone(getContainerFieldValue(accountFolioFieldNames.timeZone, response.org));
      } catch (err) {
        errorHandler(err);
      }
    };

    if (selectedOrgId === configuration.solUUID) {
      // Sol uses the pacific timezone
      setOrgTimeZone("America/Los_Angeles");
    } else {
      getOrgInfo();
    }
  }, [accountFolioFieldNames.timeZone, api, configuration.solUUID, errorHandler, selectedOrgId]);

  useEffect(() => {
    const getRecentContainers = async () => {
      try {
        setLoadingRecentContainers(true);
        const res = await api.getContainers({
          end: THE_END_OF_TIME,
          limit: 100,
          orgID: selectedOrgId,
          sortBy: "createdAt",
        });
        setRecentContainers(res.containers);
      } finally {
        setLoadingRecentContainers(false);
      }
    };

    if (recentContainers === undefined) {
      getRecentContainers();
    }
  }, [api, recentContainers, selectedOrgId]);

  const getContainerType = useCallback(
    (container: OrgsGet200ResponseContainersInner) => {
      const containerType = Some(lastSearchQuery?.type)
        ? lastSearchQuery.type
        : inferContainerTemplateType(container);

      return containerType.toLowerCase();
    },
    [inferContainerTemplateType, lastSearchQuery]
  );

  // If search is disabled, render old screen
  const { showSearchFeatureFrontend } = useFlags<LDFeatureFlags>();
  if (!showSearchFeatureFrontend) {
    return <RecordsScreen />;
  }

  const handleSearch = async (searchInput: SearchFormValues | undefined) => {
    if (searchInput === undefined) {
      setSearchResults(undefined);

      return;
    }

    if (fieldIds === undefined) {
      return;
    }

    if (
      Some(searchInput.authorizedAtStart) &&
      Some(searchInput.authorizedAtEnd) &&
      new Date(searchInput.authorizedAtStart) > new Date(searchInput.authorizedAtEnd)
    ) {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: "Issue date end must be greater than the issue date start.",
        type: "error",
      });

      return;
    }

    const emailQuery =
      searchInput.email !== undefined && searchInput.email !== ""
        ? { baseTemplateFieldID: fieldIds.merit.email, query: searchInput.email }
        : {};
    const firstNameQuery =
      searchInput.firstName !== undefined && searchInput.firstName !== ""
        ? {
            baseTemplateFieldID: fieldIds.merit.firstName,
            query: searchInput.firstName,
          }
        : {};
    const lastNameQuery =
      searchInput.lastName !== undefined && searchInput.lastName !== ""
        ? {
            baseTemplateFieldID: fieldIds.merit.lastName,
            query: searchInput.lastName,
          }
        : {};

    const fieldQuery =
      searchInput.fieldId !== undefined &&
      searchInput.fieldId !== "" &&
      searchInput.fieldQuery !== undefined &&
      searchInput.fieldQuery !== ""
        ? {
            baseTemplateFieldID: searchInput.fieldId,
            query: searchInput.fieldQuery,
          }
        : {};

    const basicQuery = [emailQuery, firstNameQuery, lastNameQuery].filter(
      cf => Object.keys(cf).length !== 0
    );
    const advancedQuery = [fieldQuery].filter(cf => Object.keys(cf).length !== 0);
    const containerFieldsQuery = Object.keys(fieldQuery).length === 0 ? basicQuery : advancedQuery;

    try {
      setIsLoading(true);
      const res = await api.search({
        orgID: selectedOrgId,
        query: {
          authorizedAtEnd: Some(searchInput.authorizedAtEnd)
            ? convertDateToTimeZone(
                endOfDate(searchInput.authorizedAtEnd).toISOString(),
                orgTimeZone
              )
            : undefined,
          authorizedAtStart: Some(searchInput.authorizedAtStart)
            ? convertDateToTimeZone(searchInput.authorizedAtStart, orgTimeZone)
            : undefined,
          containerFields: containerFieldsQuery,
          state: searchInput.state,
          templateID: searchInput.templateName,
          templateType: searchInput.type,
        },
      });

      if (res.hasMore === true) {
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          size: "medium",
          text: `Your search returned more than 500 results.  Please refine your search.`,
          type: "warning",
        });
      }

      setSearchResults(res.containers);
      setLastSearchQuery(searchInput);
    } catch (e: unknown) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveContainer = async (container: OrgsGet200ResponseContainersInner) => {
    try {
      if (lastSearchQuery === undefined && recentContainers === undefined) {
        return;
      }
      await api.deleteContainer({
        containerID: container.id,
        orgID: selectedOrgId,
      });

      setRecordToView(undefined);

      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: `A ${getContainerType(container)} has been removed.`,
        type: "success",
      });

      if (Some(searchResults)) {
        setSearchResults(prevState => prevState?.filter(sr => sr.id !== container.id));
      } else {
        setRecentContainers(prevState => prevState?.filter(sr => sr.id !== container.id));
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const revokeContainer = async (container: OrgsGet200ResponseContainersInner) => {
    if (lastSearchQuery === undefined && recentContainers === undefined) {
      return;
    }

    setIsLoading(true);
    try {
      await api.revokeContainer({
        containerID: container.id,
        orgID: selectedOrgId,
      });
      handleSearch(lastSearchQuery);
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: `A ${getContainerType(container)} has been revoked.`,
        type: "success",
      });
      setRecordToView(undefined);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const reissueContainer = async (container: OrgsGet200ResponseContainersInner) => {
    if (lastSearchQuery === undefined && recentContainers === undefined) {
      return;
    }
    setIsLoading(true);
    try {
      await api.authorizeContainer({
        containerID: container.id,
        orgID: selectedOrgId,
      });
      handleSearch(lastSearchQuery);
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: `A ${getContainerType(container)} has been reissued.`,
        type: "success",
      });
      setRecordToView(undefined);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createReissueConfirmationModal = (container: OrgsGet200ResponseContainersInner) => (
    <ConfirmationModal
      buttonText="reissue"
      onClose={() => {
        setConfirmationModal(undefined);
      }}
      onOk={() => {
        reissueContainer(container);
        setConfirmationModal(undefined);
      }}
      text={`Are you sure you want to reissue this ${getContainerType(container)}?`}
      title={`Reissue this ${getContainerType(container)}`}
    />
  );

  const createRevokeConfirmationModal = (container: OrgsGet200ResponseContainersInner) => (
    <ConfirmationModal
      buttonText="revoke"
      onClose={() => {
        setConfirmationModal(undefined);
      }}
      onOk={() => {
        revokeContainer(container);
        setConfirmationModal(undefined);
      }}
      text={`Are you sure you want to revoke this ${getContainerType(
        container
      )}? In order to reissue it you’ll have to reissue it from the revoked status`}
      title={`Revoke this ${getContainerType(container)}`}
    />
  );

  const createRemoveConfirmationModal = (container: OrgsGet200ResponseContainersInner) => (
    <ConfirmationModal
      buttonText="remove"
      onClose={() => {
        setConfirmationModal(undefined);
      }}
      onOk={() => {
        handleRemoveContainer(container);
        setConfirmationModal(undefined);
      }}
      text={`Are you sure you want to remove this ${getContainerType(
        container
      )} as an admin? You cannot view this ${getContainerType(
        container
      )} in your list anymore if you remove.`}
      title={`Remove this ${getContainerType(container)}?`}
      titleIconName="warningMediumCritical"
    />
  );

  return (
    <>
      <DefaultLayout
        breadcrumbs={[{ name: "Verify & Manage" }, { name: "Records" }]}
        testProps={{ elementName: "recordsListView", screenName: SCREEN_NAME }}
        title="Records"
      >
        <Spin spinning={isLoading}>
          <View style={styles.container}>
            <SearchForm
              onChangeTab={() => {
                resetSearch();
              }}
              onSubmit={values => {
                handleSearch(values);
              }}
            />

            {Some(searchResults) &&
              (searchResults.length > 0 ? (
                <>
                  <View style={styles.tabBody}>
                    <ScrollView>
                      <SearchResults
                        onPressDelete={container => {
                          setConfirmationModal(createRemoveConfirmationModal(container));
                        }}
                        onPressView={record => {
                          setRecordToView(record);
                        }}
                        searchResults={searchResults}
                      />
                    </ScrollView>
                  </View>
                </>
              ) : (
                <NoRecords />
              ))}

            {None(searchResults) && (
              <>
                <View style={styles.tabBody}>
                  <ScrollView>
                    {loadingRecentContainers ? (
                      <Spin />
                    ) : (
                      <SearchResults
                        onPressDelete={container => {
                          setConfirmationModal(createRemoveConfirmationModal(container));
                        }}
                        onPressView={record => {
                          setRecordToView(record);
                        }}
                        searchResults={recentContainers}
                      />
                    )}
                  </ScrollView>
                </View>
              </>
            )}
          </View>
        </Spin>
      </DefaultLayout>

      {confirmationModal}

      <Drawer isOpen={Some(recordToView)}>
        {Some(recordToView) && (
          <RecordDetails
            id={recordToView.id}
            onDrawerClose={() => {
              setRecordToView(undefined);
            }}
            onPressDelete={() => {
              setConfirmationModal(createRemoveConfirmationModal(recordToView));
            }}
            onPressReissue={() => {
              setConfirmationModal(createReissueConfirmationModal(recordToView));
            }}
            onPressRevoke={() => {
              setConfirmationModal(createRevokeConfirmationModal(recordToView));
            }}
            templateID={recordToView.templateId}
          />
        )}
      </Drawer>
    </>
  );
};
