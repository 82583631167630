// Copyright 2024 Merit International Inc. All Rights Reserved

export type LDFeatureFlags = {
  readonly allowOrgPortalAgentsCreate: boolean;
  readonly allowOrgPortalAgentsEdit: boolean;
  readonly allowOrgPortalAgentsPause: boolean;
  readonly allowOrgPortalRevokeContainer: boolean;
  readonly showPoliciesFrontend: boolean;
  readonly showJsonFieldTypes: boolean;
  readonly showAutoApproveFeature: boolean;
  readonly showAutoRevokeFeature: boolean;
  readonly showAutoMappingFeature: boolean;
  readonly showSearchFeatureFrontend: boolean;
  readonly showLinkedAppsFeature: boolean;
  readonly showStudioFrontend: boolean;
  readonly showHideDatasourceFeature: boolean;
  readonly showTemplateAnalytics: boolean;
  readonly showUpdatedOrgPortalNavigation: boolean;
  readonly showSigmaAnalytics: boolean;
  readonly showPolicyAnalytics: boolean;
};

export const featureFlagDefaults: LDFeatureFlags = {
  allowOrgPortalAgentsCreate: false,
  allowOrgPortalAgentsEdit: false,
  allowOrgPortalAgentsPause: false,
  allowOrgPortalRevokeContainer: false,
  showAutoApproveFeature: false,
  showAutoMappingFeature: false,
  showAutoRevokeFeature: false,
  showHideDatasourceFeature: false,
  showJsonFieldTypes: false,
  showLinkedAppsFeature: false,
  showPoliciesFrontend: false,
  showPolicyAnalytics: false,
  showSearchFeatureFrontend: true,
  showSigmaAnalytics: false,
  showStudioFrontend: false,
  showTemplateAnalytics: false,
  showUpdatedOrgPortalNavigation: true,
};
