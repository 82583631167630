import { Body, Button } from "@merit/frontend-components";
import { Datagrid, DatagridBody, EllipsisText } from "../../../components";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, View } from "react-native";
import { useLoadedConfigurationState } from "../../../hooks/useLoadedConfigurationState";
import React from "react";
import type { DatagridColumn } from "../../../components/Datagrid/types";
import type {
  GetAgents200ResponseAgentsInner,
  GetAllAgentsVersions200ResponseInner,
} from "../../../gen/org-portal";

const { None, Some } = Helpers;
const SCREEN_NAME = "LinkedApps";

type Props = {
  readonly appsList:
    | readonly GetAgents200ResponseAgentsInner[]
    | readonly GetAllAgentsVersions200ResponseInner[];
  readonly onPressView: (policyID: string) => void;
  readonly isLoading?: boolean;
};

export const AppsTable = ({ appsList, isLoading, onPressView }: Props) => {
  const { configuration } = useLoadedConfigurationState();

  const getOrgName = (orgId?: string, orgName?: string) => {
    if (None(orgName)) {
      return "--";
    }

    if (configuration.solUUID === orgId) {
      return configuration.solName;
    }

    return orgName;
  };

  const columns: readonly DatagridColumn<
    GetAgents200ResponseAgentsInner | GetAllAgentsVersions200ResponseInner
  >[] = [
    {
      key: "name",
      label: "Name",
      renderer: (app, testProps) => {
        const appName = "versions" in app ? app.versions[0].name : app.name;

        return <EllipsisText testProps={testProps} text={appName} />;
      },
      size: "flex",
    },
    {
      key: "description",
      label: "Description",
      renderer: (app, testProps) => {
        const description = "versions" in app ? app.versions[0].description : app.description;

        return (
          <EllipsisText
            testProps={testProps}
            text={Some(description) && description.length > 0 ? description : "--"}
          />
        );
      },
      size: "flex",
    },
    {
      key: "orgName",
      label: "Owner",
      renderer: (app, testProps) => (
        <EllipsisText
          testProps={testProps}
          text={getOrgName("versions" in app ? app.owner : app.org, app.orgName)}
        />
      ),
      size: "flex",
    },
    {
      key: "state",
      label: "State",
      renderer: (item, testProps) => (
        <Body testProps={testProps}>
          {item.state === "pending_approval" ? "Pending approval" : "Live"}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "status",
      label: "Status",
      renderer: (item, testProps) => (
        <Body capitalize testProps={testProps}>
          {item.status}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      renderer: ({ id }, testProps) => (
        <View style={{ width: 80 }}>
          <Button
            onPress={() => {
              if (Some(id)) {
                onPressView(id);
              }
            }}
            size="small"
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}ViewButton`,
                  }
                : testProps
            }
            text="View"
            type="secondary"
          />
        </View>
      ),
      size: 128,
    },
  ];

  return (
    <ScrollView>
      <Datagrid
        columns={columns}
        loading={isLoading}
        testProps={{
          elementName: "linkedAppsListView",
          screenName: SCREEN_NAME,
        }}
      >
        <DatagridBody
          columns={columns}
          data={appsList}
          getItemKey={g => g.id}
          testProps={{
            elementName: "linkedAppsListView",
            screenName: SCREEN_NAME,
          }}
          testPropsElementIdKey="id"
        />
      </Datagrid>
    </ScrollView>
  );
};
