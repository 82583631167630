// Copyright 2023 Merit International Inc. All Rights Reserved

import { AnalyticsTable, Spin, Tabs } from "../../components";
import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { ConfirmationModal } from "../../components/Modals";
import { DetailsDrawer } from "../../layouts/DetailsDrawer";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { PolicyRulesList } from "./PolicyRulesList";
import { ScrollView, StyleSheet, View } from "react-native";
import { ViewPermissions } from "../../components/ViewPermissions";
import { getDateTimeString } from "../../utils/time";
import { useAlertStore } from "../../stores";
import { useApi } from "../../api/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useNavigation } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import type { GetPolicies200ResponsePoliciesInner } from "../../gen/org-portal";
import type { GetPolicyStatistics200Response } from "@src/gen/org-portal";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { TabKey as PolicyType } from "./Policies";
import type { RouteParams } from "../../Router";

type TabKey = "overview" | "rules";

const { None, Some } = Helpers;
const SCREEN_NAME = "PolicyDetails";

type Props = {
  readonly policyID: string | undefined;
  readonly onClose: () => void;
  readonly onStatusChange: () => void;
  readonly policyType: PolicyType;
};

export const PolicyDetails = ({ onClose, onStatusChange, policyID, policyType }: Props) => {
  const { theme } = useTheme();
  const { selectedOrgId } = useLoggedInAuthState();
  const { api } = useApi();
  const { configuration } = useLoadedConfigurationState();
  const { showPolicyAnalytics } = useFlags<LDFeatureFlags>();
  const [activeTab, setActiveTab] = useState<TabKey>("overview");
  const [policyDetails, setPolicyDetails] = useState<GetPolicies200ResponsePoliciesInner>();
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { errorHandler } = useServerErrorHandler();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "Policies">>();
  const { deleteAlert, setAlert } = useAlertStore();
  const [analyticsData, setAnalyticsData] = useState<GetPolicyStatistics200Response>();

  const styles = StyleSheet.create({
    actionContainer: {
      flexDirection: "row",
    },
    divider: {
      borderBottomColor: theme.colors.border.default,
      borderBottomWidth: 1,
    },
    tabsContainer: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingHorizontal: 32,
    },
  });

  useEffect(() => {
    const getPolicy = async () => {
      try {
        setLoading(true);
        if (Some(policyID)) {
          const response = await api.getPolicy({ orgID: selectedOrgId, policyID });
          setPolicyDetails(response);
        }
      } catch (error) {
        errorHandler(error, {
          elementId: policyID,
          elementName: "fetchPolicyError",
          screenName: SCREEN_NAME,
        });
      } finally {
        setLoading(false);
      }
    };

    getPolicy();
  }, [api, errorHandler, policyID, selectedOrgId]);

  useEffect(() => {
    const getPolicyAnalytics = async () => {
      try {
        if (policyID !== undefined) {
          setAnalyticsData(undefined);
          const res = await api.getPolicyStatistics({ orgID: selectedOrgId, policyID });
          setAnalyticsData(res);
        }
      } catch (error) {
        errorHandler(error, {
          elementId: policyID,
          elementName: "fetchPolicyAnalyticsError",
          screenName: SCREEN_NAME,
        });
      }
    };

    if (showPolicyAnalytics) {
      getPolicyAnalytics();
    }
  }, [api, errorHandler, policyID, selectedOrgId, showPolicyAnalytics]);

  const changePolicyState = async () => {
    if (None(policyDetails)) {
      throw new Error("Somehow policy is not found");
    }

    try {
      setLoading(true);
      const policyState = policyDetails.state === "live" ? "paused" : "live";
      await api.editPolicy({
        orgID: selectedOrgId,
        policy: { state: policyState },
        policyID: policyDetails.id,
      });
      setPolicyDetails(previousPolicyDetails => {
        if (Some(previousPolicyDetails)) {
          return {
            ...previousPolicyDetails,
            state: policyState,
          };
        }

        return undefined;
      });
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps: {
          elementName: "updatePolicySuccess",
          screenName: SCREEN_NAME,
        },
        text: "The policy state has been updated.",
        type: "success",
      });
      onStatusChange();
    } catch (error) {
      errorHandler(error, {
        elementName: "updatePolicyError",
        screenName: SCREEN_NAME,
      });
    } finally {
      setShowConfirmationModal(false);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <View
        style={{
          backgroundColor: theme.colors.background.white,
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Spin />
      </View>
    );
  }

  if (None(policyDetails) || policyID === undefined) {
    return null;
  }

  const TABS: readonly { readonly key: TabKey; readonly label: string }[] = [
    {
      key: "overview",
      label: "Overview",
    },
    { key: "rules", label: "Rules" },
  ];

  const isOwnPolicy = selectedOrgId === policyDetails.ownerID;

  const getOwner = () => {
    if (None(policyDetails.ownerID)) {
      return "--";
    }

    return policyDetails.ownerID === configuration.solUUID
      ? configuration.solName
      : policyDetails.ownerID;
  };

  const ownAndInheritedPolicyRules = [
    ...(policyDetails.rules?.inherited?.flat() ?? []),
    ...(policyDetails.rules?.own ?? []),
  ];

  return (
    <DetailsDrawer
      actionButtons={
        <View style={styles.actionContainer}>
          {isOwnPolicy && (
            <>
              <View style={{ width: 73 }}>
                <Button
                  onPress={() => {
                    onClose();
                    navigation.navigate("EditPolicy", {
                      id: policyID,
                    });
                  }}
                  size="small"
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewEditButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="Edit"
                  type="secondary"
                />
              </View>
              <HorizontalSpacer />
            </>
          )}

          {Some(policyDetails.shareablePermissions) &&
            policyDetails.shareablePermissions.includes("extend") && (
              <View style={{ width: 92 }}>
                <Button
                  onPress={() => {
                    onClose();
                    navigation.navigate("ExtendPolicy", {
                      id: policyID,
                    });
                  }}
                  size="small"
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewExtendButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="Extend"
                  type="secondary"
                />
              </View>
            )}

          {isOwnPolicy && (
            <>
              <HorizontalSpacer />
              <View style={{ width: 92 }}>
                <Button
                  onPress={() => {
                    setShowConfirmationModal(true);
                  }}
                  size="small"
                  testProps={{
                    elementId: policyID,
                    elementName: `policyDetailsView${
                      policyDetails.state === "live" ? "Pause" : "Publish"
                    }PolicyButton`,
                    screenName: SCREEN_NAME,
                  }}
                  text={policyDetails.state === "live" ? "Pause" : "Publish"}
                  type="secondary"
                />
              </View>
            </>
          )}
        </View>
      }
      onPressClose={() => {
        setActiveTab(TABS[0].key);
        onClose();
      }}
      subTitle={
        <View style={{ flexDirection: "row" }}>
          <Heading
            level="6"
            testProps={{
              elementId: policyID,
              elementName: "policyDetailsViewPolicyId",
              screenName: SCREEN_NAME,
            }}
          >
            Policy ID: {policyID}
          </Heading>
          <HorizontalSpacer size={10} />
          <Heading color={theme.colors.text.subdued} level="6">
            |
          </Heading>
          <HorizontalSpacer size={10} />
          <Heading
            capitalize
            level="6"
            testProps={{
              elementId: policyID,
              elementName: "policyDetailsViewPolicyState",
              screenName: SCREEN_NAME,
            }}
          >
            State: {policyDetails.state}
          </Heading>
        </View>
      }
      testProps={{
        elementId: policyID,
        elementName: "policyDetailsView",
        screenName: SCREEN_NAME,
      }}
      title={policyDetails.name}
    >
      <View style={styles.tabsContainer}>
        <Tabs
          items={TABS}
          onChange={tabKey => {
            setActiveTab(tabKey);
          }}
          selected={activeTab}
          testProps={{
            elementId: policyID,
            elementName: "policyDetailsView",
            screenName: SCREEN_NAME,
          }}
        />
      </View>

      <View style={{ flex: 1, paddingHorizontal: 32, paddingVertical: 16 }}>
        {activeTab === "overview" && (
          <ScrollView showsVerticalScrollIndicator={false}>
            {showPolicyAnalytics && (
              <>
                <AnalyticsTable
                  data={
                    analyticsData === undefined
                      ? undefined
                      : [
                          { title: "Pending", value: analyticsData.pending },
                          { title: "Complete", value: analyticsData.complete },
                          { title: "Failed", value: analyticsData.failed },
                          { title: "Successful", value: analyticsData.successful },
                        ]
                  }
                  header={undefined}
                />
                <VerticalSpacer size={theme.spacing.xxl} />
              </>
            )}

            <Heading level="5">Description</Heading>
            <VerticalSpacer size={theme.spacing.xs} />
            <Body
              testProps={{
                elementId: policyID,
                elementName: "policyDetailsViewOverviewTabDescription",
                screenName: SCREEN_NAME,
              }}
            >
              {policyDetails.description === "" || None(policyDetails.description)
                ? "--"
                : policyDetails.description}
            </Body>

            {!isOwnPolicy && (
              <>
                <VerticalSpacer size={theme.spacing.xxl} />
                <Heading level="5">Owner</Heading>
                <VerticalSpacer size={theme.spacing.xs} />
                <Body
                  testProps={{
                    elementId: policyID,
                    elementName: "policyDetailsViewOverviewTabOwner",
                    screenName: SCREEN_NAME,
                  }}
                >
                  {getOwner()}
                </Body>
              </>
            )}
            <VerticalSpacer size={theme.spacing.xxl} />

            <Heading level="5">Created at</Heading>
            <VerticalSpacer size={theme.spacing.xs} />
            <Body
              testProps={{
                elementId: policyID,
                elementName: "policyDetailsViewOverviewTabCreatedAt",
                screenName: SCREEN_NAME,
              }}
            >
              {Some(policyDetails.createdAt) ? getDateTimeString(policyDetails.createdAt) : "--"}
            </Body>

            <VerticalSpacer size={theme.spacing.xxl} />
            <ViewPermissions
              permissions={policyDetails.permissions}
              testProps={{
                elementId: policyID,
                elementName: "policyDetailsViewOverviewTab",
                screenName: SCREEN_NAME,
              }}
            />
          </ScrollView>
        )}

        {activeTab === "rules" && (
          <>
            <VerticalSpacer size={28} />
            {Some(policyDetails.rules) && (
              <ScrollView showsVerticalScrollIndicator={false}>
                <Heading level="4">Inherited</Heading>
                <VerticalSpacer />

                {policyType === "shared" ? (
                  <>
                    {ownAndInheritedPolicyRules.length > 0 ? (
                      <PolicyRulesList
                        rules={ownAndInheritedPolicyRules}
                        testProps={{
                          elementId: policyID,
                          elementName: "policyDetailsViewRulesTab",
                          screenName: SCREEN_NAME,
                        }}
                      />
                    ) : (
                      <Body
                        testProps={{
                          elementId: policyID,
                          elementName: "policyDetailsViewRulesTabNoOwnRulesPlaceholder",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        There are no inherited rules
                      </Body>
                    )}
                  </>
                ) : (
                  <>
                    {Some(policyDetails.rules.inherited) &&
                    policyDetails.rules.inherited.length > 0 ? (
                      <PolicyRulesList
                        rules={policyDetails.rules.inherited.flat()}
                        testProps={{
                          elementId: policyID,
                          elementName: "policyDetailsViewRulesTab",
                          screenName: SCREEN_NAME,
                        }}
                      />
                    ) : (
                      <Body
                        testProps={{
                          elementId: policyID,
                          elementName: "policyDetailsViewRulesTabNoInheritedRulesPlaceholder",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        There are no inherited rules
                      </Body>
                    )}
                    <VerticalSpacer size={24} />
                    <View style={styles.divider} />
                    <VerticalSpacer size={24} />

                    <Heading level="4">Org created</Heading>
                    <VerticalSpacer />
                    {Some(policyDetails.rules.own) && policyDetails.rules.own.length > 0 ? (
                      <PolicyRulesList
                        onPressView={templateID => {
                          navigation.navigate("Templates", { initialTemplateId: templateID });
                          setActiveTab(TABS[0].key);
                          onClose();
                        }}
                        rules={policyDetails.rules.own}
                        testProps={{
                          elementId: policyID,
                          elementName: "policyDetailsViewRulesTab",
                          screenName: SCREEN_NAME,
                        }}
                      />
                    ) : (
                      <Body
                        testProps={{
                          elementId: policyID,
                          elementName: "policyDetailsViewRulesTabNoOrgRulesPlaceholder",
                          screenName: SCREEN_NAME,
                        }}
                      >
                        There are no org created rules
                      </Body>
                    )}
                  </>
                )}
              </ScrollView>
            )}
          </>
        )}
      </View>
      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => {
            setShowConfirmationModal(false);
          }}
          onOk={() => {
            changePolicyState();
          }}
          testProps={{
            elementName: `policy${
              policyDetails.state === "live" ? "Pause" : "Publish"
            }Confirmation`,
            screenName: SCREEN_NAME,
          }}
          text={
            policyDetails.state === "live"
              ? "By pausing this policy, some linked apps may not function properly."
              : "By publishing this policy, you are allowing linked apps to access and use this policy."
          }
          title={`Are you sure you want to ${
            policyDetails.state === "live" ? "pause" : "publish"
          } this policy?`}
        />
      )}
    </DetailsDrawer>
  );
};
